import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

// import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import './CidadesComponents.css';

const CidadesComponents = () => {
    const [cidades, setCidades] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCidade, setEditingCidade] = useState(null);
    const [nome, setNome] = useState("");
    const [endereco, setEndereco] = useState("");
    const [telefone, setTelefone] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [contato, setContato] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCidades();
    }, []);

    const fetchCidades = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cidades`);
            setCidades(response.data);
        } catch (error) {
            console.error("Erro ao buscar os cidades:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCidade(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNome("");
        setEndereco("");
        setTelefone("");
        setWhatsapp("");
        setContato("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditRural = (cidade) => {
        setIsEditing(true);
        setEditingCidade(cidade);
        setNome(cidade.nome);
        setEndereco(cidade.endereco);
        setTelefone(cidade.telefone);
        setWhatsapp(cidade.whatsapp);
        setContato(cidade.contato);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/cidades/${cidade.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCidade = async (cidadeId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/cidades/${cidadeId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCidades(); // Atualizar a lista de cidades

            toast.success('Cidade deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o cidade:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCidadeId, setDeleteModalCidadeId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("endereco", endereco);
        formData.append("foto", foto);
        formData.append("telefone", telefone);
        formData.append("whatsapp", whatsapp);
        formData.append("contato", contato);

        try {
            if (isEditing && editingCidade) {
                await axios.put(`${process.env.REACT_APP_API_URL}/cidades/${editingCidade._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/cidades`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCidades();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o cidade:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const [imagemFundo, setImagemFundo] = useState('');

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <Container>
        <div className="row">
            <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button onClick={handleOpenModal} size="sm" variant="outline-primary">
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                    <h3><i className="fa fa-fw fa-shopping-bag" />  Endereço de Nossas Lojas</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th className="col111">Imagem</th>
                                <th className="col222">Cidade</th>
                                <th className="col333">Endereço</th>
                                <th className="col444">Telefone</th>
                                <th className="col555">Whatsapp</th>
                                <th className="col666">Horário Atendimento</th>
                                <th className="acoes">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cidades.map((cidade) => (
                                <tr key={cidade._id}>
                                    <td>
                                        <div className="cidade-img">
                                            <img src={`${process.env.REACT_APP_API_IMG}/assets/cidades/${cidade.foto}`} alt="Cidade" />
                                        </div>
                                    </td>
                                    <td>{cidade.nome}</td>
                                    <td>{cidade.endereco}</td>
                                    <td>{cidade.telefone}</td>
                                    <td>{cidade.whatsapp}</td>
                                    <td>{cidade.contato}</td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditRural(cidade)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteModalCidadeId(cidade._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Tem certeza que deseja excluir este cidade? {deleteModalCidadeId}
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                            Cancelar
                                                        </Button>
                                                        <Button variant="danger" onClick={() => { handleDeleteCidade(deleteModalCidadeId); setShowDeleteModal(false); }}>
                                                            Excluir
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isEditing ? "Editar Cidade" : "Cadastrar Cidade"}
                            <span>Atenção para as dimensões das imagens recomendadas:  (400x400 pixels)</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Cidade</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Endereço</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={endereco} onChange={(e) => setEndereco(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Telefone</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Whatsapp</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Horário de atendimento</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={contato} onChange={(e) => setContato(e.target.value)} required />
                                </div>
                            </FormGroup>
                            {/* <FormGroup>
                                <Form.Label>Telefone</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup> */}
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange}  />
                                    {previewImage && <img src={previewImage} alt="Preview"  style={{width: '200px'}} />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </Container>
    );
};

export default CidadesComponents;