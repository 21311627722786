import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Pagination, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import './MessagesTable.css';

const MessagesTable = () => {
  const [messages, setMessages] = useState([]); // Estado iniciado como array vazio
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMessages(currentPage);
  }, [currentPage]);

  const fetchMessages = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/messages?page=${page}`
      );
      // Verifique se a resposta é um array ou um objeto com paginação
      if (Array.isArray(response.data)) {
        // Se for um array, atribua-o diretamente
        setMessages(response.data);
        setCurrentPage(1);
        setTotalPages(1);
      } else {
        // Se for um objeto com dados de paginação
        setMessages(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
      toast.error("Erro ao carregar as mensagens.");
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/messages/${id}`);
      toast.success("Mensagem excluída com sucesso!");
      fetchMessages(currentPage);
    } catch (error) {
      console.error("Erro ao excluir a mensagem:", error);
      toast.error("Erro ao excluir a mensagem.");
    }
  };

  const handleView = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
  };

  // Renderiza os controles de paginação apenas se totalPages for maior que 1
  const renderPagination = () => {
    if (totalPages <= 1) return null;
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return (
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
        />
      </Pagination>
    );
  };

  return (
    <div className="container my-4">
      <div className="carousel-h3">
        <h3 className="mb-4">
          <i className="fa fa-fw fa-envelope" /> Mensagens do Site
        </h3>
        <div className="mensagens-site">
          {loading ? (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Assunto</th>
                  <th>Data</th> {/* Coluna para a data */}
                  <th className="acoes-table">Ações</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message) => (
                  <tr key={message._id}>
                    <td>{message.name}</td>
                    <td>{message.email}</td>
                    <td>{message.subject}</td>
                    <td>{new Date(message.timestamp).toLocaleString()}</td> {/* Formata a data */}
                    <td className="acoes-table">
                      <Button
                        variant="outline-primary"
                        className="mr-sm-2"
                        size="sm"
                        onClick={() => handleView(message)}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="mr-sm-2"
                        size="sm"
                        onClick={() => handleDelete(message._id)}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>

        <div className="d-flex justify-content-center my-3">
          {renderPagination()}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes da Mensagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedMessage && (
              <>
                <p>
                  <strong>Nome:</strong> {selectedMessage.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedMessage.email}
                </p>
                <p>
                  <strong>Telefone:</strong> {selectedMessage.tel}
                </p>
                <p>
                  <strong>Assunto:</strong> {selectedMessage.subject}
                </p>
                <p>
                  <strong>Mensagem:</strong> {selectedMessage.message}
                </p>
                <p>
                  <strong>Data:</strong> {new Date(selectedMessage.timestamp).toLocaleString()}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MessagesTable;
