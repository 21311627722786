import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

// import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import './CardPlansTv.css';

const CardPlansTv = () => {
    const [plansTvs, setPlansTvs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingPlanoTv, setEditingPlanoTv] = useState(null);
    const [name, setName] = useState("");
    const [channels, setChannels] = useState("");
    const [extraChannels, setExtraChannels] = useState("");
    const [description, setDescription] = useState("");
    const [text, setText] = useState("");
    const [price, setPrice] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchPlansTvs();
    }, []);

    const fetchPlansTvs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/planoTvs`);
            setPlansTvs(response.data);
        } catch (error) {
            console.error("Erro ao buscar os planos:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingPlanoTv(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setName("");
        setDescription("");
        setText("");
        setExtraChannels("");
        setChannels("");
        setPrice("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditRural = (planoTv) => {
        setIsEditing(true);
        setEditingPlanoTv(planoTv);
        setName(planoTv.name);
        setDescription(planoTv.description);
        setText(planoTv.text);
        setChannels(planoTv.channels);
        setExtraChannels(planoTv.extraChannels);
        setPrice(planoTv.price);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/planoTvs/${planoTv.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeletePlanoTv = async (planoTvId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/planoTvs/${planoTvId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchPlansTvs(); // Atualizar a lista de planoTvs

            toast.success('PlanoTv deletado com sucesso!', { position: 'top-right', autoClose: 2000 });
        } catch (error) {
            console.error("Erro ao deletar o planoTv:", error);
        }
    };



    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalPlanoTvId, setDeleteModalPlanoTvId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("text", text);
        formData.append("foto", foto);
        formData.append("channels", channels);
        formData.append("extraChannels", extraChannels);
        formData.append("price", price);

        try {
            if (isEditing && editingPlanoTv) {
                await axios.put(`${process.env.REACT_APP_API_URL}/planoTvs/${editingPlanoTv._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/planoTvs`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchPlansTvs();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o planoTv:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const [imagemFundo, setImagemFundo] = useState('');

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <Container>
            <div className="row">
                <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button onClick={handleOpenModal} size="sm" variant="outline-primary">
                        <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                    <h3><i className="fa fa-fw fa-television" /> Cadastrar Planos</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th className="coltv">Imagem</th>
                                <th className="coltv1">Plano de Tv</th>
                                <th className="coltv2">Canais</th>
                                <th className="coltv3">Canais Extras</th>
                                <th className="coltv4">Descrição</th>
                                <th className="coltv5">Informação Detalhada</th>
                                <th className="coltv6">Preço</th>
                                <th className="acoes">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plansTvs.map((planoTv) => (
                                <tr key={planoTv._id}>
                                    <td>
                                        <div className="planoTv-img">
                                            <img src={`${process.env.REACT_APP_API_IMG}/assets/planoTvs/${planoTv.foto}`} alt="PlanoTv" />
                                        </div>
                                    </td>
                                    <td>{planoTv.name}</td>
                                    <td>{planoTv.channels}</td>
                                    <td>{planoTv.extraChannels}</td>
                                    <td>{planoTv.description}</td>
                                    <td><TextRender htmlContent={planoTv.text} /></td>
                                    <td>{planoTv.price}</td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditRural(planoTv)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteModalPlanoTvId(planoTv._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Tem certeza que deseja excluir este planoTv? {deleteModalPlanoTvId}
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                            Cancelar
                                                        </Button>
                                                        <Button variant="danger" onClick={() => { handleDeletePlanoTv(deleteModalPlanoTvId); setShowDeleteModal(false); }}>
                                                            Excluir
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div >

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar PlanoTv" : "Cadastrar PlanoTv"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label className="form-label">Plano de Tv</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl className="custom-form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Descrição</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Canais</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={channels} onChange={(e) => setChannels(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Canais Extras</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={extraChannels} onChange={(e) => setExtraChannels(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px', marginLeft: '0px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Preço</Form.Label>
                                <div className='text-icon-tv'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} />
                                    {previewImage && <img src={previewImage} className="img-preview" alt="Preview"  style={{width: '200px'}} />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </Container >
    );
};

export default CardPlansTv;