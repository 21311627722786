import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Row, Col, Table } from 'react-bootstrap';
import TV from '../../../assets/images/IconTvNet.png';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './ComboCard.css';

const ComboCard = () => {
  const [cards, setCards] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [planModalIsOpen, setPlanModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [newCard, setNewCard] = useState({
    title: '',
    subtitle: '',
    text: ''
  });
  // newPlan inclui _id se for edição; caso contrário, é novo.
  const [newPlan, setNewPlan] = useState({ planname: '', internetprice: '', discountprice: '', tvplan: '', tvprice: '', numberofchannels: '' });
  const [isEditingPlan, setIsEditingPlan] = useState(false); // false = novo plano, true = edição

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card_combos`);
      setCards(response.data);
    } catch (error) {
      console.error('Erro ao buscar os cards:', error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const openModal = () => {
    setEditCard(null);
    setNewCard({ title: '', subtitle: '', text: '' });
    setModalIsOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editCard) {
        await axios.put(`${apiUrl}/card_combos/${editCard._id}`, newCard);
        toast.success('Card atualizado com sucesso!');
      } else {
        await axios.post(`${apiUrl}/card_combos`, newCard);
        toast.success('Card cadastrado com sucesso!');
      }
      setModalIsOpen(false);
      setNewCard({ title: '', subtitle: '', text: '' });
      fetchCards();
    } catch (error) {
      console.error('Erro ao salvar card:', error);
      toast.error('Erro ao salvar card.');
    }
  };

  const handleEdit = (card) => {
    setEditCard(card);
    setNewCard({ title: card.title, subtitle: card.subtitle, text: card.text });
    setModalIsOpen(true);
  };

  const handleDeleteConfirmation = (card) => {
    setSelectedCard(card);
    setDeleteModalIsOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/card_combos/${selectedCard._id}`);
      toast.success('Card deletado com sucesso!');
      fetchCards();
      setDeleteModalIsOpen(false);
    } catch (error) {
      console.error('Erro ao excluir o card:', error);
      toast.error('Erro ao deletar Card!');
    }
  };

  // Abrir modal para adicionar um novo plano
  const handleAddPlan = (card) => {
    setSelectedCard(card);
    setIsEditingPlan(false);
    setNewPlan({ planname: '', internetprice: '', discountprice: '', tvplan: '', tvprice: '', numberofchannels: '' });
    setPlanModalIsOpen(true);
  };

  // Abrir modal para editar um plano existente
  const handleEditPlan = (card, plan) => {
    setSelectedCard(card);
    setIsEditingPlan(true);
    setNewPlan(plan); // plan já deve conter o _id
    setPlanModalIsOpen(true);
  };

  // Submeter plano: se é edição ou novo
  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    try {
      if (isEditingPlan && newPlan._id) {
        // Atualiza plano: PUT /card_combos/:cardId/planos/:planId
        await axios.put(`${apiUrl}/card_combos/${selectedCard._id}/planos/${newPlan._id}`, newPlan);
        toast.success('Plano atualizado com sucesso!');
      } else {
        // Adiciona novo plano: POST /card_combos/:cardId/planos
        await axios.post(`${apiUrl}/card_combos/${selectedCard._id}/planos`, newPlan);
        toast.success('Plano adicionado com sucesso!');
      }
      setPlanModalIsOpen(false);
      setNewPlan({ planname: '', internetprice: '', discountprice: '', tvplan: '', tvprice: '', numberofchannels: '' });
      fetchCards();
    } catch (error) {
      console.error('Erro ao cadastrar/atualizar plano:', error);
      toast.error('Erro ao salvar plano!');
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Novo
    </Tooltip>
  );

  return (
    <div className='container'>
      <div className='combo-container'>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Button variant="outline-primary" size='sm' onClick={openModal}>
            <i className="fa fa-plus-square-o" aria-hidden="true"></i>
          </Button>
        </OverlayTrigger>
        <h3>
          <i className="fa fa-wifi" aria-hidden="true"></i> Cadastrar Combo
        </h3>
      </div>
      <div className='table-container'>
        {/* Tabela dos Cards */}
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Título</th>
              <th>Subtítulo</th>
              <th>Texto</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card) => (
              <tr key={card._id}>
                <td>{card.title}</td>
                <td>{card.subtitle}</td>
                <td>{card.text}</td>
                <td>
                  <Button variant="warning" size='sm' onClick={() => handleEdit(card)}>Editar</Button>{' '}
                  <Button variant="danger" size='sm' onClick={() => handleDeleteConfirmation(card)}>Excluir</Button>{' '}
                  <Button variant="info" size='sm' onClick={() => handleAddPlan(card)}>Adicionar Plano</Button>{' '}
                  {/* Se o card tiver planos, exiba um botão para editar cada um */}
                  {card.planos && card.planos.map((plan) => (
                    <Button
                      key={plan._id}
                      variant="secondary"
                      size="sm"
                      onClick={() => handleEditPlan(card, plan)}
                      style={{ margin: '0 2px' }}
                    >
                      Editar {plan.planname}
                    </Button>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal de Criar/Editar Card */}
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='icon-tv'>
              <img src={TV} alt='icon-tv' /> {editCard ? 'Editar Card' : 'Novo Combo'}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formTitle">
              <Form.Label column sm={2}>Título</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Título"
                  value={newCard.title}
                  onChange={(e) => setNewCard({ ...newCard, title: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formSubtitle">
              <Form.Label column sm={2}>Subtítulo</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Subtítulo"
                  value={newCard.subtitle}
                  onChange={(e) => setNewCard({ ...newCard, subtitle: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formText">
              <Form.Label column sm={2}>Texto</Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  placeholder="Texto"
                  rows={3}
                  value={newCard.text}
                  onChange={(e) => setNewCard({ ...newCard, text: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit">
              {editCard ? 'Atualizar' : 'Criar'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal de Adicionar/Editar Plano */}
      <Modal show={planModalIsOpen} onHide={() => setPlanModalIsOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='icon-tv'>
              <img src={TV} alt='icon-tv' /> {isEditingPlan ? 'Editar Plano' : 'Adicionar Plano'} ao Combo: {selectedCard?.title}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitPlan}>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanName">
                  <Form.Label column sm={6} className="label">Plano de Internet</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Nome do Plano"
                      value={newPlan.planname || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, planname: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanInternetPrice">
                  <Form.Label column sm={6} className="label">Valor da Internet</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Preço da Internet"
                      value={newPlan.internetprice || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, internetprice: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanDiscountPrice">
                  <Form.Label column sm={6} className="label">Desconto</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Valor do Desconto"
                      value={newPlan.discountprice || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, discountprice: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanTv">
                  <Form.Label column sm={6} className="label">Plano de TV</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Nome do Plano de TV"
                      value={newPlan.tvplan || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, tvplan: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanTvPrice">
                  <Form.Label column sm={6} className="label">Valor da TV</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Preço do Plano de TV"
                      value={newPlan.tvprice || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, tvprice: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlanChannels">
                  <Form.Label column sm={6} className="label">Canais</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="Quantidade de canais"
                      value={newPlan.numberofchannels || ''}
                      onChange={(e) => setNewPlan({ ...newPlan, numberofchannels: e.target.value })}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              {isEditingPlan ? 'Atualizar Plano' : 'Adicionar Plano'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal de Confirmação de Exclusão */}
      <Modal show={deleteModalIsOpen} onHide={() => setDeleteModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir o card "{selectedCard?.title}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalIsOpen(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ComboCard;
