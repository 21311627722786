import jsPDF from "jspdf";
import { toast } from "react-toastify";

const generateCurriculumPDF = async (curriculoId) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Token não encontrado. Faça login novamente.");
      return;
    }

    // Busca dos dados
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/curriculos/${curriculoId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      toast.error("Sessão expirada. Faça login novamente.");
      return;
    }
    if (!response.ok) {
      toast.error("Erro ao buscar os dados do currículo.");
      return;
    }

    const curriculo = await response.json();
    const storedUser = JSON.parse(localStorage.getItem("user")) || {};

    const userName =
      curriculo.userId?.name || storedUser.name || "Nome não informado";
    const userEmail =
      curriculo.userId?.email || storedUser.email || "Email não informado";
    const userPhone = curriculo.telefone || "N/A";

    // Inicia o jsPDF
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Definindo margens e espaçamento
    let yPosition = 20;               // margem superior inicial
    const xPosition = 15;            // margem esquerda
    const maxWidth = pageWidth - 2 * xPosition; // largura máxima de texto
    const lineHeight = 7;            // altura de linha para texto
    const bottomMargin = 20;         // margem inferior para troca de página

    /**
     * Função para adicionar um título (heading).
     */
    const addHeading = (heading) => {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);

      // Se não couber na página atual, cria uma nova
      if (yPosition + lineHeight > pageHeight - bottomMargin) {
        doc.addPage();
        yPosition = 20;
      }

      doc.text(heading, xPosition, yPosition);
      yPosition += lineHeight + 2; // avançamos um pouco mais
    };

    /**
     * Função para adicionar blocos de texto (cada bloco pode ser um parágrafo).
     * Recebe um array de strings (cada string é um parágrafo ou item).
     */
    const addTextBlock = (textArray) => {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);

      textArray.forEach((paragraph) => {
        // Divide o texto em linhas que caibam no maxWidth
        const lines = doc.splitTextToSize(paragraph, maxWidth);

        lines.forEach((line) => {
          // Verifica se precisa de nova página
          if (yPosition + lineHeight > pageHeight - bottomMargin) {
            doc.addPage();
            yPosition = 20;
          }
          doc.text(line, xPosition, yPosition);
          yPosition += lineHeight;
        });

        // Espaçamento após cada parágrafo
        yPosition += lineHeight;
      });
    };

    /**
     * Se tiver foto, adiciona.
     */
    if (curriculo.foto) {
      const imageUrl = `${process.env.REACT_APP_API_IMG}/assets/curriculos/${curriculo.foto}`;
      const imageData = await fetch(imageUrl)
        .then((res) => res.blob())
        .then(
          (blob) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            })
        );

      // Insere a imagem no PDF (30x30 de tamanho, por exemplo)
      doc.addImage(imageData, "JPEG", xPosition, yPosition, 30, 30);
    }

    // Adiciona dados de contato ao lado da foto
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);
    doc.text(userName, xPosition + 35, yPosition + 10);

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(userEmail, xPosition + 35, yPosition + 16);
    doc.text(userPhone, xPosition + 35, yPosition + 22);

    // Move a posição vertical para baixo da foto/contato
    yPosition += 40;

    /**
     * SEÇÃO: Resumo
     */
    if (curriculo.perfil) {
      addHeading("Resumo");
      // Remove tags HTML e insere como texto simples
      const plainText = curriculo.perfil
        .replace(/<[^>]+>/g, " ")
        .replace(/&nbsp;/g, " ");
      addTextBlock([plainText]);
    }

    /**
     * SEÇÃO: Experiência Profissional
     */
    if (curriculo.experiencia && curriculo.experiencia.length > 0) {
      addHeading("Experiência Profissional");
      // Transforma cada experiência em um item de texto
      const expTextArray = curriculo.experiencia.map((exp) =>
        "- " + exp.replace(/<[^>]+>/g, " ").replace(/&nbsp;/g, " ")
      );
      addTextBlock(expTextArray);
    }

    /**
     * SEÇÃO: Formação Acadêmica
     */
    if (curriculo.formacao && curriculo.formacao.length > 0) {
      addHeading("Formação Acadêmica");
      const formacaoArray = curriculo.formacao.map((form) =>
        "- " + form.replace(/<[^>]+>/g, " ").replace(/&nbsp;/g, " ")
      );
      addTextBlock(formacaoArray);
    }

    /**
     * SEÇÃO: Outros Cursos
     */
    if (curriculo.curso && curriculo.curso.length > 0) {
      addHeading("Outros Cursos");
      const cursoArray = curriculo.curso.map((c) =>
        "- " + c.replace(/<[^>]+>/g, " ").replace(/&nbsp;/g, " ")
      );
      addTextBlock(cursoArray);
    }

    /**
     * SEÇÃO: Áreas de Interesse
     */
    const areaLabels = {
      ti: "Tecnologia da Informação",
      administrativa: "Administrativa",
      callCenter: "Atendimento em Call Center",
      atendimentoPublico: "Atendimento ao Público",
      vendedor: "Vendas",
    };
    if (curriculo.areasInteresse) {
      addHeading("Áreas de Interesse");
      const areasArray = [];
      Object.entries(curriculo.areasInteresse).forEach(([area, value]) => {
        if (value) {
          areasArray.push("- " + (areaLabels[area] || area));
        }
      });
      addTextBlock(areasArray);
    }

    /**
     * SEÇÃO: Cidades de Interesse
     */
    const cidadeLabels = {
      goiania: "Goiânia/Aparecida de Goiânia",
      jaragua: "Matriz Jaraguá",
      ceres: "Ceres, Carmo do Rio Verde, Nova Glória e Rianápolis",
      uruana: "Uruana e Braslandia",
      uruacu: "Uruaçu",
    };
    if (curriculo.cidadesInteresse) {
      addHeading("Cidades de Interesse");
      const cidadesArray = [];
      Object.entries(curriculo.cidadesInteresse).forEach(([cid, value]) => {
        if (value) {
          cidadesArray.push("- " + (cidadeLabels[cid] || cid));
        }
      });
      addTextBlock(cidadesArray);
    }

    /**
     * SEÇÃO: Redes Sociais
     */
    if (curriculo.redesSociais && curriculo.redesSociais.length > 0) {
      addHeading("Redes Sociais");
      const redesArray = curriculo.redesSociais.map(
        (rede) => `- ${rede.tipo}: ${rede.url}`
      );
      addTextBlock(redesArray);
    }

    // Abre o PDF em uma nova aba
    window.open(doc.output("bloburl"), "_blank");
  } catch (error) {
    console.error("Erro ao gerar PDF:", error);
    toast.error("Erro ao gerar o PDF. Tente novamente.");
  }
};

export default generateCurriculumPDF;
