import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const CardSale = () => {
    const [cards, setCards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCard, setEditingCard] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [price, setPrice] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCards();
    }, []);

    const fetchCards = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cards`);
            setCards(response.data);
        } catch (error) {
            console.error("Erro ao buscar os cards:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCard(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setPrice("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditCard = (card) => {
        setIsEditing(true);
        setEditingCard(card);
        setTitle(card.title);
        setSub(card.sub);
        setPrice(card.price);
        setText(card.text);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/cards/${card.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCard = async (cardId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/cards/${cardId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCards(); // Atualizar a lista de cards

            toast.success('Card deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o card:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCardId, setDeleteModalCardId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("price", price);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingCard) {
                await axios.put(`${process.env.REACT_APP_API_URL}/cards/${editingCard._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/cards`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCards();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o card:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button onClick={handleOpenModal} size="sm" variant="outline-primary">
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className="table-container">
                    <table className="tabela">
                        <thead>
                            <tr>
                                <th className="col1">Imagem</th>
                                <th className="col2">Nome</th>
                                <th className="col3">Descrição</th>
                                <th className="col4">Detalhes</th>
                                <th className="col5">Preço</th>
                                <th className="col6">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cards.map((card) => (
                                <tr key={card._id}>
                                    <td>
                                        <div className="imagem">
                                            <img
                                                // src={`https://apicardapio.gigabytetelecom.com.br:3001/assets/cards/${card.foto}`}
                                                src={`${process.env.REACT_APP_API_IMG}/assets/cards/${card.foto}`}
                                                alt="product"
                                            />
                                        </div>
                                    </td>
                                    <td>{card.title}</td>
                                    <td>{card.sub}</td>
                                    <td>
                                        <TextRender htmlContent={card.text} />
                                    </td>
                                    <td>{card.price}</td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" onClick={() => handleEditCard(card)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    onClick={() => {
                                                        setDeleteModalCardId(card._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                        </div>
                                        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirmar Exclusão</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Tem certeza que deseja excluir este produto? {deleteModalCardId}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="danger" onClick={handleDeleteCard}>
                                                    Excluir
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Card" : "Cadastrar Card"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Preço</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange}  />
                                    {previewImage && <img src={previewImage} alt="Preview"  style={{width: '200px'}} />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div >
    );
};

export default CardSale;