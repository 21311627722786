import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Spinner, Pagination } from 'react-bootstrap';

const ChatbotUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchUsers = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/chatbotlistusers?page=${page}&limit=20`
      );
      setUsers(response.data.data);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
    setLoading(false);
  };

  const deleteUser = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este usuário?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/chatbotlistusers/${id}`);
        // Após a exclusão, recarregue os usuários da página atual
        fetchUsers(currentPage);
      } catch (error) {
        console.error('Erro ao excluir usuário:', error);
      }
    }
  };

  // Renderiza os itens de paginação
  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return (
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Pagination>
    );
  };

  return (
    <div className="container chatbot-tabela">
      <h3>
        <i className="fa fa-th-list" aria-hidden="true"></i> Lista de Usuários do Chatbot
      </h3>
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Assunto</th>
              <th>Data</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.assunto}</td>
                <td>{new Date(user.createdAt).toLocaleString()}</td>
                <td className="acoes-table">
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => deleteUser(user._id)}
                  >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {renderPagination()}
    </div>
  );
};

export default ChatbotUsersTable;
