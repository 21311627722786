import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import "./PromotionForm.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import { Table } from "react-bootstrap";

const PromotionForm = () => {
  const [promotions, setPromotions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPromotion, setEditingPromotion] = useState(null);
  const [title, setTitle] = useState("");
  const [sub, setSub] = useState("");
  const [text, setText] = useState("");
  const [foto, setFoto] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotions`);
      setPromotions(response.data);
    } catch (error) {
      console.error("Erro ao buscar os promotions:", error);
    }
  };

  const handleOpenModal = () => {
    setIsEditing(false);
    setEditingPromotion(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTitle("");
    setSub("");
    setText("");
    setFoto(null);
    setPreviewImage(null);
  };

  const handleEditPromotion = (promotion) => {
    setIsEditing(true);
    setEditingPromotion(promotion);
    setTitle(promotion.title);
    setSub(promotion.sub);
    setText(promotion.text);
    setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/promotions/${promotion.foto}`);
    setShowModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeletePromotion = async (cardId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/promotions/${cardId}`);
      handleCloseDeleteModal(); // Fechar o modal após a exclusão
      fetchPromotions(); // Atualizar a lista de promotions

      toast.success('Promotion deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
    } catch (error) {
      console.error("Erro ao deletar o promotion:", error);
    }
  };


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalPromotionId, setDeleteModalPromotionId] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("sub", sub);
    formData.append("foto", foto);
    formData.append("text", text);

    try {
      if (isEditing && editingPromotion) {
        await axios.put(`${process.env.REACT_APP_API_URL}/promotions/${editingPromotion._id}`, formData);
        toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/promotions`, formData);
        toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
      }

      fetchPromotions();
      handleCloseModal();
    } catch (error) {
      console.error("Erro ao cadastrar/editar o promotion:", error);
    }
  };

  const handleFotoChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      const imageURL = URL.createObjectURL(selectedImage);
      setPreviewImage(imageURL);
      setFoto(selectedImage);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="title">
          <Button
            onClick={handleOpenModal}
            variant="outline-primary"
            size="sm"
          >
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
          </Button>
          <h3><i className="fa fa-fw fa-newspaper-o"></i> Cadastro de avisos barra superior</h3>
        </div>
        <div>
        </div>
        
        <Table striped bordered hover className="promotion-table">
          <thead>
            <tr>
              <th className="promotion-img">Imagem</th>
              <th className="promotion-title">Título</th>
              <th className="promotion-sub">Subitítulo</th>
              <th className="promotion-text">Texto</th>
              <th className="promotion-actions">Ações</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((promotion) => (
              <tr key={promotion._id}>
                <td>
                  <div className="promotion-img">
                    <img src={`${process.env.REACT_APP_API_IMG}/assets/promotions/${promotion.foto}`} alt="Promotion" />
                  </div>
                </td>
                <td>{promotion.title}</td>
                <td>{promotion.sub}</td>
                <td><TextRender htmlContent={promotion.text} /></td>
                <td>
                  <Button variant="outline-primary" size="sm" onClick={() => handleEditPromotion(promotion)}>
                    <i class="fa fa-edit" aria-hidden="true"></i>
                  </Button>
                  {' '}
                  <Button variant="outline-danger" size="sm" onClick={() => { setShowDeleteModal(true); setDeleteModalPromotionId(promotion._id); }}>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza que deseja excluir este promotion? {deleteModalPromotionId}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={() => { handleDeletePromotion(deleteModalPromotionId); setShowDeleteModal(false); }}>
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="row">
        <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Editar Promotion" : "Cadastrar Promotion"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>

              <FormGroup>
                <Form.Label>Título</Form.Label>
                <div className='text-icon'>
                  <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                  <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                </div>
              </FormGroup>
              <FormGroup>
                <Form.Label>Subtítulo</Form.Label>
                <div className='text-icon'>
                  <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                  <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                </div>
              </FormGroup>
              <FormGroup>
                <Form.Label>Texto</Form.Label>
                <div className='text-icon'>
                  <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                  <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="foto-preview">
                  <Form.Label>Foto</Form.Label>
                  <FormControl type="file" onChange={handleFotoChange} />
                  {previewImage && <img src={previewImage} alt="Preview" style={{ width: '200px' }} />}
                </div>
              </FormGroup>
              <br />
              <Button variant="primary" type="submit">
                {isEditing ? "Editar" : "Cadastrar"}
              </Button>{' '}
              <Button variant="secondary" onClick={handleCloseModal}>
                Fechar
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

      </div>
    </div>
  );
};

export default PromotionForm;