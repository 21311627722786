import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Form, Card, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import ListUser from "./ListUser";

import './RegisterForm.css';

const RegisterForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(''); // se quiser exibir na tela também

  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_API_URL}/register`, {
      name,
      email,
      password,
      confPassword,
    })
      .then((response) => {
        // Sucesso:
        toast.success('Registrado com sucesso!', { position: 'top-center' });
        console.log(response.data);
        // Exemplo: limpar campos ou navegar
        setName(''); setEmail(''); setPassword(''); setConfPassword('');
        // navigate('/algumaRota');
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          // Mensagem enviada pelo backend
          setErrorMsg(error.response.data.message);
          toast.error(error.response.data.message, { position: 'top-center' });
        } else {
          // Mensagem genérica
          setErrorMsg('Ocorreu um erro inesperado.');
          toast.error('Ocorreu um erro inesperado.', { position: 'top-center' });
        }
      });
  };

  return (
    <>
      <div
        className="formulario"
        style={{
          paddingTop: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <ToastContainer />
        <div className="row">
          <div className="col col-sm-12">
            <div className="title-register">
              <i className="fa fa-user" aria-hidden="true"> </i> Cadastrar usuário
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-12" style={{ width: '500px' }}>
            <Card>
              <Form onSubmit={handleRegister} style={{ padding: '20px' }}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Form.Label>Confirmar Senha</Form.Label>
                <Form.Control
                  type="password"
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                  required
                />
                <Button
                  style={{ marginTop: '10px' }}
                  variant="primary"
                  type="submit"
                >
                  Registrar
                </Button>
              </Form>
            </Card>
          </div>
        </div>
      </div>
      {/* <ListUser /> */}
    </>
  );
};

export default RegisterForm;
